@charset "UTF-8";

/*-----------------------------------------------
component
-----------------------------------------------*/
/*---- area  ----*/
.container {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.contents {
    max-width: 830px;
    height: 100%;
    margin: 0 auto;
}

.content {
    padding:  100px 0 70px;

    @include tab {
        padding: 10% 5%;
    }

    @include mb {
        padding: 15% 5% 12%;
    }
}

/*---- button  ----*/
.stdBtn {
    display: block;
    max-width: 390px;
    min-height: 58px;
    margin: 0 auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $keyColor;
    border-radius: 6px;
    color: $baseColor;
    transition: .3s;

    @include mb {
        max-width: 100%;
        min-height: 50px;
    }

    .stdBtn__icon {
        max-width: 36px;
        margin-right: 10px;
    }

    .stdBtn__txt {
        margin-right: 10px;
    }
}

/*---- box  ----*/
.basicBox {
    display: flex;
    align-items: center;

    @include mb {
        display: block;
    }

    .basicBox__img {
        max-width: 50%;
        padding-right: 20px;

        @include tab {
            padding-right: 2.5%;
        }

        @include mb {
            max-width: 100%;
            margin-bottom: 5%;
            padding-right: 0;
        }

        img {
            max-width: 400px;

            @include mb {
                width: 100%;
            }
        }
    }

    .basicBox__sec {
        max-width: 50%;
        padding-left: 20px;

        @include tab {
            padding-left: 2.5%;
        }

        @include mb {
            max-width: 100%;
            padding-left: 0;
        }

        .mainTtl {
            text-align: left;

            @include pc {
                margin-bottom: 30px;
            }

            @include tab {
                margin-bottom: 20px;
            }
        }
    }
}

.newsCard {
    overflow: hidden;
    margin-bottom: 50px;

    @include mb {
        margin-bottom: 5%;
    }

    .newsCard__ttl {
        margin-bottom: 30px;
        font-size: $sizeLL;

        @include mbTab {
            margin-bottom: 5%;
            font-size: $sizeL;
        }

        @include tab {
            margin-bottom: 20px;
        }

        .newsCard__ttlDeta {
            display: block;
            font-size: 1.4rem;
            color: $keyColor;
        }
    }
    .newsCard__img {
        float: left;
        margin-right: 20px;

        @include mb {
            margin: 0 0 5% 0;
        }
    }
}

/*---- font  ----*/
@import "font";

/*---- input  ----*/
input,
select {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    font-size: 1.4rem;
    line-height: 1.8;
    letter-spacing: 0.08em;

    @include mbTab {
        height: 44px;
        padding: 0 2%;
        font-size: 1.6rem;
    }
}

select {
    &:focus {
        outline: none;
    }
}

textarea {
    width: 100%;
    height: 50px;
    padding: 10px;
    font-size: 1.4rem;
    line-height: 1.8;
    letter-spacing: 0.08em;

    @include mbTab {
        height: 44px;
        padding: 2%;
        font-size: 1.6rem;
    }
}

button {
    font-size: 1.2rem;
    line-height: 1.8;
    letter-spacing: 0.08em;
}

//必須の「※」マーク
.requiredMark {
    color: $keyColor;
    padding-left: 6px;
}

/*---- text  ----*/
.sizeS {
    font-size: $sizeS;
}

.orgTxt {
    color: $keyColor;
}

/*---- title  ----*/
.mainTtl {
    margin-bottom: 50px;
    text-align: center;

    @include mbTab {
        margin-bottom: 10%;
    }

    @include tab {
        margin-bottom: 40px;
    }

    img {
        width: auto;
        max-height: 28px;

        @include mbTab {
            max-height: 19px;
        }
    }

    &.mainTtl--mb {
        @include pc {
            display: none;
        }

        @include tab {
            display: none;
        }
    }

    &.mainTtl--pc {
        @include mb {
            display: none;
        }
    }
}

.subTtl {
    margin-bottom: 20px;

    @include mb {
        margin-bottom: 5%;
    }

    img {
        width: auto;
        max-height: 20px;

        @include mb {
            max-height: 15px;
        }
    }
}

.logoTxtTtl {
    margin-bottom: 30px;
    text-align: center;

    @include mbTab {
        margin-bottom: 10%;
    }

    @include tab {
        margin-bottom: 5%;
    }

    img {
        max-width: 200px;
        margin-left: 30px;

        @include mbTab {
            max-width: 150px;
            margin-left: 20px;
        }
    }

    span {
        display: block;
        font-size: 3.0rem;

        @include mbTab {
            font-size: 2.2rem;
        }
    }
}

/*---- img ----*/
.cmsImg {
    width: 100%;
    max-width: 400px;
    height: 247px;
    border-radius: 8px;
    background: $color_3 no-repeat center / cover;

    @include tab {
        max-width: 300px;
        height: 185px;
    }

    @include mb {
        max-width: 100%;
        height: 200px;
    }
}

/*---- deco ----*/
.boderBtm {
    position: absolute;
    bottom: -20px;
    width: 100%;
    height: 20px;
    z-index: 1;
    background: url(../images/img_boderBtm-org.png) no-repeat center bottom / cover;

    @include mbTab {
        bottom: -10px;
    }

    &.boderBtm--wrt {
        background-image: url(../images/img_boderBtm-wrt.png);
    }
    &.boderBtm--be {
        background-image: url(../images/img_boderBtm-be.png);
    }
}

/*---- modal ----*/

// モーダル・ダイアログ設定
.modal {
    text-align: left;

    .modal__cont--outer {
        @include mbTab {
            margin: 5%;
        }
    }

    .modal__cont {
        padding: 30px;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

        @include mbTab {
            padding: 5%;
        }

        .modal__head {
            margin-bottom: 20px;
            border-bottom: 1px solid $keyColor;

            @include mbTab {
                margin-bottom: 5%;
            }

            .modal__headTtl {
                margin-bottom: 20px;
                text-align: center;

                @include mbTab {
                    margin-bottom: 5%;
                }
            }

            .modal__headClose {
                width: 20px;
                height: 20px;
                line-height: 0;
                font-size: 1.6rem;
            }
        }

        .modal__body {
            margin-bottom: 30px;
            padding: 0 4px;
            position: relative;

            @include mbTab {
                padding: 0 2%;
                margin-bottom: 5%;
            }
        }

        .modal__foot {
            text-align: right;

            .modal__footClose {
                padding: 10px 20px;
                background: $keyColor;
                color: $baseColor;
            }
        }
    }
}
