@charset "UTF-8";

/*-----------------------------------------------
    variable
-----------------------------------------------*/

// color
$baseColor:    #fff;
$fontColor:    #000;
$keyColor:     #f9a825;
$color_1:      #7c7c7c;
$color_2:      #000;
$color_3:      #fff6dc;
$borderColor:  #7c7c7c;
$inputColor:   #f9a825;
$linkColor:    #000;

// font size
$fontsizeBase: 62.5%; //font-size 1em = 10px on default browser settings
$sizeSS: 1rem;
$sizeS: 1.2rem;
$sizeM: 1.6rem;
$sizeL: 1.8rem;
$sizeLL: 2.0rem;
$sizeLLL: 2.4rem;
$fontsizeSubTitle: $sizeL * .8;

// size
$pcNum: 8px;
$mbNum: 2.5%;

$radiusSize: 4px;

// url
$imgPath: "../images/"; //"http://";
