@charset "UTF-8";

/*-----------------------------------------------
  NEWS
-----------------------------------------------*/

.news {
    &.news--detail {
        .news__cont {
            position: relative;
            background: $color_3;

            .content {
                padding:  80px 0 70px;

                @include mbTab {
                    padding: 15% 5% 12%;
                }

                @include tab {
                    padding: 10% 5%;
                }
            }

            .news__contTtl {
                margin-bottom: 30px;
                text-align: center;

                @include mb {
                    margin-bottom: 10%;
                }

                img {
                    max-width: 190px;

                    @include mbTab {
                        max-width: 118px;
                    }
                }
            }
        }
    }
}
