@charset "UTF-8";
/*-----------------------------------------------
    all
-----------------------------------------------*/
html {
    height: 100%;
    min-height: 100%;
    font-size: $fontsizeBase;
    background: $baseColor;
}

body {
    min-height: 100%;
    margin: 0 auto;
    padding: 0;
    line-height: 1.8;
    letter-spacing: 0.08em;
    color: $fontColor;
    @include fontFamilySansSerif();

    @include pc {
        font-size: 1.4rem;
    }

    @include tab {
        font-size: 1.4rem;
    }

    @include mb {
        font-size: 1.4rem;
    }
}

a {
    color: $linkColor;
    text-decoration: none;

    &:hover {
        opacity: .8;
        transition: .3s;
    }
}

img {
    width: 100%;
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
}

/*-----------------------------------------------
    generic
-----------------------------------------------*/
/*---- block  ----*/
.dsn {
    display: none !important;
}

.bdn {
    border: none !important;
}

/*--- clearfix ---*/
.clear {
    clear: both;
}

.cf {
    display: block;

    &::after {
        clear: both;
        visibility: hidden;
        display: block;
        height: 0;
        content: "";
    }
}

/*---- display  ----*/
.mbOn {
    @include pc {
        display: none;
    }
}

.mbOff {
    @include mbTab {
        display: none;
    }
}
/*---- height  ----*/
.lh_0 {
    line-height: 0;
}

/*-----------------------------------------------
    component
-----------------------------------------------*/
@import "components";

/*-----------------------------------------------
    common
-----------------------------------------------*/
/*---- .gnav  ----*/
.gnav--content {
    max-width: 1040px;
    margin: 0 auto;

    .gnav {
        position: fixed;
        z-index: 10;
        width: 100%;
        max-width: 60px;
        background: $color_2;
        border-radius: 4px;

        @include pc {
            margin-top: 20px;
        }

        .gnav__btn {
            width: 100%;
            height: 60px;
            position: relative;

            .gnav__btnImg {
                position: absolute;
                top: 8px;
                right: -2px;

                img {
                    width: 54px;
                    height: auto;
                }
            }
        }

        .gnav__nav {
            &.nav {
                .navItem {
                    a {
                        display: block;
                        width: 100%;
                        height: 60px;
                        color: $baseColor;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .navItem__icon {
                            text-align: center;

                            img {
                                width: 26px;
                                text-align: center;
                                margin-bottom: 4px;
                                margin: 0 auto;
                            }

                            span {
                                display: block;
                                font-size: $sizeSS;
                            }
                        }

                        @include pc {
                            &:hover {
                                opacity: 0.6;
                            }
                        }

                        // 現在地
                        &.navItem--on {
                            background: $keyColor;
                        }
                    }

                    &:last-of-type {
                        // 現在地の最後だけ
                        .navItem--on {
                            border-radius: 0 0 4px 4px;
                        }
                    }
                }
            }
        }
    }
}

/*---- .footer  ----*/
.footer {
    .content {
        padding:  50px 0;

        @include mbTab {
            padding: 10% 0 5%;
        }
    }

    .footer__copyright {
        font-size: $sizeSS;
        text-align: center;
    }
}

/*---- .admitShop（協賛加盟店）  ----*/
.admitShop {
    position: relative;

    .content {
        padding:  100px 0 40px;

        @include tab {
            padding: 10% 5% 5%;
        }

        @include mb {
            padding: 15% 5% 7%;
        }
    }

    .shopList {
        display: flex;
        flex-wrap: wrap;
        margin-right: -50px;

        @include tab {
            margin-right: -5%;
        }

        @include mb {
            display: block;
            margin-right: 0;
        }

        .shopList__item {
            width: 50%;
            margin-bottom: 30px;
            padding-right: 50px;

            @include tab {
                padding-right: 5%;
            }

            @include mb {
                width: 100%;
                margin-bottom: 5%;
                padding-right: 0;
            }

            a {
                .shopList__itemImg {
                    position: relative;
                    width: 100%;
                    height: 247px;
                    margin-bottom: 20px;
                    border-radius: 8px;
                    background: $color_3 no-repeat center / cover;

                    @include tab {
                        height: 185px;
                    }

                    @include mb {
                        height: 200px;
                        margin-bottom: 3%;
                    }

                    &:hover {
                        opacity: 0.8;
                    }

                    .shopList__itemImg--stamp {
                        position: absolute;
                        right: -20px;
                        bottom: -20px;

                        @include mbTab {
                            right: -10px;
                        }

                        img {
                            max-width: 77px;

                            @include mbTab {
                                max-width: 60px;
                            }
                        }
                    }
                }

                .shopList__itemTtl {
                    font-size: $sizeM;
                }
            }

        }
    }

}

/*---- .errorページ  ----*/
.error {
    .error__cont {
        position: relative;
        background: $color_3;

        .content {
            padding:  80px 0 70px;

            @include mbTab {
                padding: 15% 5% 12%;
            }

            @include tab {
                padding: 10% 5%;
            }
        }

        .error__contTxt {
            margin-bottom: 60px;

            @include mbTab {
                margin-bottom: 15%;
            }

            @include tab {
                margin-bottom: 10%;
            }
        }
    }
}
