@charset "UTF-8";

/*-----------------------------------------------
home
-----------------------------------------------*/

.home {
    &.home--index {

        .home__head {
            position: relative;
            background: $keyColor;

            .home__headlogo {
                text-align: center;
                margin-bottom: 80px;

                @include mbTab {
                    margin-bottom: 15%;
                }

                @include tab {
                    margin-bottom: 60px;
                }

                img {
                    max-width: 274px;

                    @include mbTab {
                        max-width: 198px;
                    }
                }
            }

            .home__headNews {
                max-width: 600px;
                margin: 0 auto;

                &.headNews {
                    a {
                        display: block;

                        &:hover {
                            color: $color_3;
                        }
                    }

                    .headNews__date {
                        float: left;
                        clear: both;
                        font-size: $sizeS;
                        line-height: 25px;
                        color: $color_3;

                        @include mb {
                            float: none;
                        }
                    }

                    .headNews__ttl {
                        padding-left: 100px;
                        margin-bottom: 10px;


                        @include mb {
                            padding-left: 0;
                            margin-bottom: 5%;
                        }
                    }
                }
            }
        }

        .home__first {
            position: relative;

            .basicBox {
                .basicBox__sec {
                    padding-bottom: 20px;

                    @include mb {
                        padding-bottom: 0;
                    }
                }
            }
        }

        .home__about {
            position: relative;
            background: $color_3;

            .home__aboutTtl {
                margin-bottom: 60px;
                text-align: center;

                @include mbTab {
                    margin-bottom: 10%;
                }

                @include tab {
                    margin-bottom: 50px;
                }

                img {
                    max-width: 240px;

                    @include mbTab {
                        max-width: 135px;
                    }
                }
            }

            .home__aboutTxt {
                margin-bottom: 50px;

                @include mbTab {
                    margin-bottom: 5%;
                }

                @include tab {
                    margin-bottom: 30px;
                }
            }
        }

        .home__recipe {
            position: relative;
            background: $color_3;

            .home__recipeBox {
                display: flex;

                @include mbTab {
                    display: block;
                }

                .recipeBox {
                    width: 33.3%;

                    @include mbTab {
                        width: 100%;
                        margin-bottom: 10%;
                    }

                    @include tab {
                        margin-bottom: 20px;
                    }

                    &:last-of-type {
                        @include mbTab {
                            margin-bottom: 0;
                        }
                    }

                    .recipeBox__ttl {
                        margin-bottom: 10px;
                        font-size: 1.6rem;

                        @include mbTab {
                            margin-bottom: 5%;
                        }

                        @include tab {
                            margin-bottom: 20px;
                        }
                    }

                    .recipeBox__box {
                        @include mbTab {
                            display: flex;
                        }

                        .recipeBox__boxImg {
                            margin-bottom: 20px;
                            text-align: center;

                            @include mbTab {
                                margin-bottom: 0;
                                padding-right: 5%;
                            }

                            @include tab {
                                padding-right: 30px;
                            }

                            img {
                                max-width: 203px;

                                @include mbTab {
                                    max-width: 110px;
                                }
                            }
                        }

                        .recipeBox__boxTxt {
                            display: flex;
                            align-items: center;
                        }
                    }
                }

                .arrow {
                    display: flex;
                    align-items: center;
                    margin-bottom: 40px;

                    @include mbTab {
                        display: none;
                    }

                    img {
                        max-width: 46px;
                    }
                }
            }
        }

        .home__join {
            position: relative;

            .home__join--inner {
                position: relative;

                .home__joinImg {
                    position: absolute;
                    top: -50px;
                    right: 0;
                    z-index: 1;

                    @include mbTab {
                        top: -40px;
                    }

                    img {
                        max-width: 200px;

                        @include tab {
                            max-width: 150px;
                        }

                        @include mb {
                            max-width: 120px;
                        }
                    }
                }
            }
        }

        .home__contact {
            position: relative;
            background: $color_3;

            .home__contactTtl {
                margin-bottom: 70px;
                text-align: center;

                @include mbTab {
                    margin-bottom: 8%;
                }

                @include tab {
                    margin-bottom: 30px;
                }

                img {
                    max-width: 196px;

                    @include mbTab {
                        max-width: 118px;
                    }

                }
            }

            .form {
                .form__block {
                    margin-bottom: 30px;
                    display: flex;

                    @include mbTab {
                        margin-bottom: 5%;
                        display: block;
                    }

                    @include tab {
                        margin-bottom: 20px;
                    }

                    .form__blockTtl {
                        display: block;
                        width: 300px;
                        line-height: 50px;

                        @include mbTab {
                            width: 100%;
                            line-height: 44px;
                        }
                    }

                    .form__blockData {
                        width: 100%;
                        display: flex;

                        input {
                            border: 1px solid $keyColor;
                            border-radius: 6px;
                            background: $baseColor;

                            &:focus {
                                border: 3px solid $keyColor;
                                transition: .3s;
                            }
                        }

                        textarea {
                            height: 200px;
                            background: $baseColor;
                            border: 1px solid $keyColor;
                            border-radius: 6px;
                            resize: vertical;

                            @include mbTab {
                                height: 140px;
                            }

                            &:focus {
                                border: 3px solid $keyColor;
                                transition: .3s;
                            }
                        }

                        &.form__blockData--name {
                            input {
                                &:first-of-type  {
                                    margin-right: 20px;

                                    @include mbTab {
                                        margin-right: 2%;
                                    }
                                }
                                &:last-of-type {
                                    margin-left: 20px;

                                    @include mbTab {
                                        margin-left: 2%;
                                    }
                                }
                            }
                        }
                    }
                }

                .form__error {
                    margin-bottom: 30px;
                    padding-left: 220px;
                    color: $keyColor;
                    font-size: 1.2rem;

                    @include mbTab {
                        margin-bottom: 5%;
                        padding-left: 0;
                    }

                    @include tab {
                        margin-bottom: 20px;
                    }
                }

                .form__sendBtn {
                    margin-top: 70px;
                    text-align: center;

                    @include mbTab {
                        margin-top: 12%;
                    }

                    .sendBtn {
                        width: 100%;
                        max-width: 390px;
                        line-height: 58px;
                        margin: 0 auto;
                        background: $keyColor;
                        border-radius: 6px;
                        transition: .3s;

                        @include mb {
                            max-width: 100%;
                            line-height: 58px;
                        }

                        &:hover {
                            opacity: 0.8;
                            cursor: pointer;
                        }

                        img {
                            max-width: 36px;
                            margin-right: 10px;
                            vertical-align: middle;
                        }

                        .sendBtn__txt {
                            margin-right: 20px;
                            font-size: 1.4rem;
                            color: $baseColor;
                        }
                    }
                }
            }
        }
    }
}
