@charset "UTF-8";

/*-----------------------------------------------
  SHOP
-----------------------------------------------*/

.shop {
    &.shop--detail {

        .shop__slider {
            overflow: hidden;

            .slider {
                .slider__list {
                    margin-bottom: 0;

                    .slider__listItem {
                        height: 500px;
                        background: no-repeat center / cover;

                        @include mbTab {
                            height: 200px;
                        }

                        @include tab {
                            height: 400px;
                        }
                    }
                }

                .slick-dots {
                    bottom: 20px;

                    button:before {
                        color: #fff;
                    }
                }
            }
        }

        .shop__intro {
            position: relative;

            .content {
                overflow: hidden;
                padding:  60px 0 70px;

                @include tab {
                    padding: 8% 5% 10%;
                }

                @include mb {
                    padding: 10% 5% 12%;
                }
            }

            .shop__introName {
                &.introName {
                    margin-bottom: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @include mb {
                        margin-bottom: 5%;
                    }

                    .introName__stamp {
                        max-width: 60px;

                        @include mbTab {
                            max-width: 40px;
                        }
                    }

                    .introName__txt {
                        padding: 0 10px;
                        font-size: 2.8rem;

                        @include mbTab {
                            font-size: 2.0rem;
                        }
                    }
                }
            }

            .shop__introImg {
                &.introImg {
                    float: left;
                    margin: 0 20px 0 0;

                    @include mb {
                        float: none;
                        margin: 0;
                    }

                    .introImg__pict {
                        width: 390px;
                        height: 241px;
                        margin-bottom: 20px;
                        border-radius: 6px;
                        background: $color_3 no-repeat center / cover;

                        @include tab {
                            width: 280px;
                            height: 173px;
                        }

                        @include mb {
                            width: 100%;
                            height: 200px;
                            margin-bottom: 5%;
                        }

                        &:last-of-type {
                            margin-bottom: 0;

                            @include mb {
                                margin-bottom: 2%;
                            }
                        }
                    }
                }
            }

            .shop__introTtl {
                padding: 15px 0;
                font-size: $sizeL;

                @include mb {
                    padding: 5% 0;
                }
            }
        }

        .shop__info {
            position: relative;
            background: $color_3;

            .shop__infoName {
                margin-bottom: 40px;
                font-size: $sizeL;

                @include mbTab {
                    margin-bottom: 10%;
                }

                @include tab {
                    margin-bottom: 5%;
                }
            }

            .shop__infoDef {
                &.infoDef {
                    margin-bottom: 30px;
                    overflow: hidden;

                    @include mbTab {
                        margin-bottom: 5%;
                    }

                    @include tab {
                        margin-bottom: 2.5%;
                    }

                    .infoDef__ttl {
                        float: left;
                        clear: both;

                        @include mbTab {
                            float: none;
                        }
                    }

                    .infoDef__data {
                        margin-bottom: 10px;
                        padding-bottom: 10px;
                        padding-left: 120px;
                        border-bottom: 1px solid $baseColor;

                        @include mbTab {
                            margin-bottom: 5%;
                            padding-bottom: 5%;
                            padding-left: 1em;
                        }

                        @include tab {
                            margin-bottom: 2.5%;
                            padding-bottom: 2.5%;
                        }

                        &:last-of-type {
                            margin-bottom: 0;
                            border-bottom: 0;
                        }
                    }
                }
            }

            .shop__infoMap {
                .infoMap {
                    width: 100%;
                    height: 300px;
                    margin-bottom: 50px;
                    border-radius: 6px;

                    @include mbTab {
                        height: 240px;
                        margin-bottom: 10%;
                    }

                    @include tab {
                        margin-bottom: 5%;
                    }
                }
            }
        }

        .shop_contact {
            position: relative;
            background: $color_3;

            .basicBox{

                .basicBox__img {
                    width: 40%;
                    text-align: center;

                    @include mb {
                        width: 100%;
                    }

                    img {
                        max-width: 196px;

                        @include mbTab {
                            max-width: 98px;
                        }
                    }
                }

                .basicBox__sec {
                    width: 60%;

                    @include mb {
                        width: 100%;
                    }

                    .mainTtl {
                        @include mb {
                            text-align: center;
                        }
                    }

                    .basicBox__txt {
                        margin-bottom: 30px;

                        @include mb {
                            margin-bottom: 5%;
                        }
                    }
                }
            }
        }
    }
}
